<template>
  <div class="introduce">
    <div class="info">{{title}}</div>
    <div class="more" @click="toCompany" :style="toPathName?'cursor:pointer;':''">More</div>
  </div>
</template>

<script>
export default {
  name: 'my-title',
  props:['title',"toPathName"],
  methods:{
    toCompany(){
      if (this.toPathName){
        this.$router.push({name:this.toPathName})
      }
    }
  }
}
</script>

<style scoped lang="scss">
.introduce {
  display: flex;
  border-bottom: 1px solid #ccc;
  color: #999999;
  div {
    line-height: 53px;
  }
  .info {
    text-align: left;
    margin-left: 10px;
    border-bottom: 2px solid #077BC1;
  }
  .more {
    flex: 1;
    text-align: right;
    margin-right: 10px;
  }
}
</style>
