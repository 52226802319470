<template>
	<div>
		<div class="header">
			<div class="header-left">
				<img src="../../static/LOGO3.png">
			</div>
			<div class="header-right">
				<li>
					<router-link to="/home" :class="{'hoverChange':this.$route.meta.index == 1}" @click.native="this.$route.meta.index=1">
						首页
					</router-link>
				</li>
				<li>
					<router-link to="/company"  :class="{'hoverChange':this.$route.meta.index == 2}" @click.native="this.$route.meta.index=2">
					  公司简历
					</router-link>	
				</li>
				<li >
					<router-link to="/mainService" :class="{'hoverChange':this.$route.meta.index == 3}" @click.native="this.$route.meta.index = 3">
					  主营业务
					</router-link>
				</li>
				<li>
					<router-link to="/information"  :class="{'hoverChange':this.$route.meta.index == 4}" @click.native="this.$route.meta.index =4">
					  行业资讯
					</router-link>
				</li>
				<li>
					<router-link to="/contact"  :class="{'hoverChange':this.$route.meta.index == 5}" @click.native="this.$route.meta.index = 5">
					  联系我们
					</router-link>
				</li>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "nav-head",
		data(){
			return {
			}
		}
	}
</script>

<style scoped lang="scss">
		.header {
			height: 100%;
			display: flex;
			justify-content: space-between;
			background: #FFFFFF;
			img {
				margin-top: 1em;
				margin-left: 2em;
			}
			.header-right {
				display: flex;
				padding-right: 5rem;
				li {
					display: inline;
					width: 6rem;
					padding: 0 1em;
					line-height: 4em;
					list-style: none;
					cursor: pointer;
					text-align: center;	
					a{
						display: block;
						width: 6rem;
						text-decoration: none;
						color: #333333;
					}
					a:hover {
						color: #077BC1;
					}
				}
				.hoverChange {
					background-color: #077BC1;
					color: #FFFFFF;
				}
				.hoverChange:hover {
					color: red;
				}
			}
		}
		
		
		
</style>