<template>
	<div class="warpper">
		<img class="banner" src="../../../static/banner_home.png">
		<div class="content-box">
			<div class="content-left" style="width: 20%">
				<div class="content-left-top" >
					<div class="main">
						<div class="main-info">
							主营业务
						</div>
					</div>
					<div class="li" v-for="(item,index) in serverMap">
						<li @click="toBusiness(index)" style="cursor:pointer;">
							<img src="../../../static/triangle.png" >&nbsp;
							{{item.title}}
						</li>
					</div>

				</div>
				<div class="content-left-middle">
					<div class="main">
						<div class="main-info">
							联系我们
						</div>
					</div>
          <div class="tell-me">
            <p>地址：北京市朝阳区清河营东路2号院乐想汇3号楼1112室</p>
            <p>电话：15101644348</p>
            <!--					<p>传真：010-82980020 </p>-->
            <p>网址：www.iceflowcool.com</p>
          </div>
				</div>
			</div>
      <div style="width:80%">
        <div style="display: flex">
          <div class="content-center">
            <myTitle title="公司介绍" toPathName="companyProfile"></myTitle>
            <companyProfile style="margin: 0 auto;height: 100%"></companyProfile>
          </div>
          <div class="content-right">
            <my-title title="行业资讯" toPathName="information" />
            <div class="consult" v-for="(item,index) in infoList" :key="index"  @click="jumpToDesc(item.id)" style="">
                <div class="con">{{item.title}}</div>
                <div>></div>
              </div>
          </div>
        </div>
        <div class="footer">
<!--          <div class="footer-left"></div>-->
          <div class="footer-right">
            <my-title title="典型客户" />
            <div class="customer">
              <div v-for="(item,index) in customerList" :key="index">
                <img :src="item.img" alt="" />
                <p>{{item.companyName}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>

		</div>

	</div>
</template>

<script>
import {
  getInformation, list
} from "@/api";
	import CompanyProfile from "@/views/components/Company.vue";
	import myTitle from '@/components/Title'
	export default {
		// eslint-disable-next-line vue/multi-word-component-names
		name: 'Home',
		components: {
			CompanyProfile,
			myTitle
		},
		data() {
			return {
			infoList:[] ,//行业咨询列表数据
        serverMap:[
          {
            title:"施工安装"
          },{
            title:"群控节能"
          },{
            title:"维修维保"
          },{
            title:"清洗服务"
          }
        ],
			customerList:[
				{
					img:require('../../../static/img_client_01.png'),
					companyName:'北京菜市口百货股份有限公司'
				},
				{
					img:require('../../../static/img_client_02.png'),
					companyName:'北京城市副中心东方大厦'
				},
				{
					img:require('../../../static/img_client_03.png'),
					companyName:'北京大学生命科学学院科研大楼'
				},
				{
					img:require('../../../static/img_client_04.png'),
					companyName:'北京建材经贸大厦'
				},
				{
					img:require('../../../static/img_client_05.png'),
					companyName:'北京飞机维修工程有限公司……'
				},
				{
					img:require('../../../static/img_client_06.png'),
					companyName:'北京天赋河套酒店'
				},
				{
					img:require('../../../static/img_client_07.png'),
					companyName:'中国航发北京航科发动机控制系'
				},
				{
					img:require('../../../static/img_client_08.png'),
					companyName:'中国航天员训练中心'
				}
			]
		}
		},
		created() {
			this.getInformationList();
		},
		methods: {
			getInformationList(){
			getInformation({isPublished:1}).then( (res) => {
				console.log(res.data.rows)
        if (res.data.code === 200){
          this.infoList = res.data.rows.slice(0,13)
        }

			})
			},
			jumpToDesc(id){
				this.$router.push({
					path:'/information-detail',
					query:{
						id
					}
				})
				this.$route.meta.index = 4
			},
      toBusiness(index){
        this.$router.push({
          name:"mainService",
          params:{
            index
          }
        })
      }
		}
	}
</script>
<style scoped lang="less">
	.banner {
		width: 100%;
		background-size: 100%, 100%;
	}
	.content-box {
		width: 100%;
		display: flex;
    padding: 19px 20px 100px;
		.content-left {
			.content-left-top {
				background: #FFF;
				padding-bottom: 20px;
				margin-bottom: 20px;
				li {
					list-style: none;
					margin: 20px;
				}
			}
		}
		.content-left-middle {
			background: #FFFFFF;
			padding-bottom: 20px;
			p {
				text-align: left;
        padding: 8px 20px 0 0;
			}
      .tell-me{
        font-size: 14px;
        padding: 14px 0 14px 20px;
      }
		}
		.content-center {
		  background: #FFF;
		  width: 65%;
		  overflow: hidden;
      margin-left: 20px;
		}
		.content-right {
			width: 30%;
			background: #FFFFFF;
      margin-left: 20px;
			.consult {
        padding-top: 13px;
        padding-bottom: 13px;
        padding: 0 17px;
				font-size: 10px;
        cursor:pointer;
        display: flex;
        align-items: center;
        justify-content: space-between;
				//li:hover {
				//background-color: #077BC1;
				//color: #ffffff;
				//}
			}
		}
	}
	.footer {
		display: flex;
    padding-right: 20px;
    padding-left: 20px;
    margin-top: 20px;
		.footer-right {
			width: 100%;
			background: #FFFFFF;
			justify-items: center;
			.customer {
				display: flex;
				flex-wrap: wrap;
				justify-content: space-around;
				padding:  17px 19px;
				div {
					width: 23%;
					padding-top: 26px;
          p{
            font-size: 14px;
            color: #333;
            margin: 0;
            padding-top: 14px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
				}
				img {
					width: 100%;
				}
			}
		}
	}

	.main {
		width: 80%;
		line-height: 53px;
		margin-left: 10px;
		border-bottom: 1px solid #ccc;
	}

	.main-info {
		text-align: left;
		margin-left: 0.625rem;
		border-bottom: 2px solid #077BC1;
		width: 34%;
	}
  .con{
    font-size: 14px;
    color: #333;
    margin: 0;
    padding-top: 7px;
    padding-bottom: 7px;
    max-width: 80%;
    overflow:hidden;
    text-overflow:ellipsis;
    white-space: nowrap;
    -webkit-line-clamp: 1;
  }
	@media screen and(max-width:900px) {
		.content-center {
		  display: none;
		}
	}

</style>
