import { render, staticRenderFns } from "./information-detail.vue?vue&type=template&id=52787a72&scoped=true&"
import script from "./information-detail.vue?vue&type=script&lang=js&"
export * from "./information-detail.vue?vue&type=script&lang=js&"
import style0 from "./information-detail.vue?vue&type=style&index=0&id=52787a72&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "52787a72",
  null
  
)

export default component.exports