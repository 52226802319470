<template>
<div class="warpper">
 <div class="page"><router-link to="/home">首页</router-link><span> > <router-link to="/information">行业资讯</router-link> > 详情</span></div>
  <div class="detail">
	  <p v-html="content"></p>
  </div>
</div>
</template>

<script>
	import {getInfoDetail} from '@/api/index'
export default {
  name: "information-detail",
  data(){
    return {
      content:'',
    }
  },
  mounted() {
	  // console.log(this.$route.query.id)
	  this.getInformationDesc()
  },
  methods:{
	  getInformationDesc(){
		  getInfoDetail({id:this.$route.query.id}).then((res) => {
			  if(res.status === 200){
				  this.content = res.data.data.informationDesc
			  }

		  })
	  }
  }
}
</script>

<style scoped lang="scss">
  .warpper {
    width: 80%;
    margin: 0 auto;
    background-color: rgb(240,240,245);

    .page {
      line-height: 3.75em;
      background-color: rgb(240,240,245);
      color: #999999;
      cursor: pointer;
      a {
        text-decoration: none;
        color: #999999;
      }
    }
	.detail {
		height: 100%;
		background-color: #ffffff;
		padding: 3.75rem;
		h4 {
			border-radius: 0px;
			text-align: center;
			line-height: 3.75rem;
			margin-bottom: 2.5rem;
		}
		.publishDate {
			font-size: 14px;
			font-family: Microsoft YaHei;
			font-weight: 400;
			color: #999999;
			line-height: 1.875rem;
			margin-left: 3.75rem;
		}
		.imgbox {
			padding: 3.125rem 0;
			height: 100%;
			div {
				text-align: center;
				width: 100%;
				height: 100%;
				padding-bottom: 1%;
			}
			img {
				width: 62.5rem;
			}
		}
	}
	.info {
		p {
			line-height: 1.875rem;
			text-indent: 1.875rem;
		}
	}
	.introduction {
		padding-top: 1.875rem;
		.feature {
			padding: 1.875rem;
			.feature-top {
				display: flex;
				.feature-top-left {
					 margin: 2.5rem;
				}
				.feature-top-right {
					margin-left: 2.5rem;
					p {
						margin-top: 2%;
						line-height: 1.875rem;
						text-indent: 1.875rem;
					}
				}
			}
		}
	}
  }
</style>
