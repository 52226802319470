<template>
	<div class="layout">
		<my-head class="head" v-if="$route.meta.top"></my-head>
		<div class="page">
			<router-view >
			</router-view>
		</div>
		<foot class="foot" v-if="$route.meta.foot"></foot>
	</div>
</template>

<script>
	import myHead from '@/components/head.vue'
	import foot from '@/components/foot.vue'
	export default {
		name:'layout',
		components:{
			myHead,
			foot
		}
	}
</script>

<style scoped>
	.layout {
		height: 100%;
	}
	.head {
		position: fixed;
		width: 100%;
		top: 0;
	}
	.foot {
		position: fixed;
		width: 100%;
		bottom: 0;
	}
	.page {
		margin-top: 4rem;
		padding-bottom: 4rem;
	}
</style>