<template>
	<div class="warpper">
		<div class="page"><router-link to="/home">首页</router-link><span> >主营业务 > {{title[currentIndex].tit}}</span>
		</div>
		<div class="center">
			<div class="center-left">
				<div class="main">
					<div :class="{'colo': currentIndex==0 }" @click="left(0)">
						施工安装
					</div>

				</div>
				<div class="main">
					<div :class="{'colo': currentIndex==1 }" @click="left(1)">
						群控节能
					</div>

				</div>
				<div class="main">

					<div :class="{'colo': currentIndex==2 }" @click="left(2)">
						维修维保
					</div>
				</div>
				<div class="main">
					<div :class="{'colo': currentIndex==3 }" @click="left(3)">
						清洗服务
					</div>

				</div>
			</div>
			<!-- @mouseenter="stop" @mouseleave="start" -->
			<div class="center-rigth">
				<!-- 左箭头 -->
				<div class="rigth">
					<div class="rigth-left" @click="up()">
						<img src="../../../static/images/arrow_l.png" alt="">
					</div>
					<!-- 中间跳转图片部分 -->
					<div class="center-img" :style="changePic">
						<img :src="imgList[currentIndex].src" />
						<!-- <img :src="imgList[0]" alt=""> -->
					</div>
					<!-- 右箭头 -->
					<div class="rigth-rigth" @click="down()">
						<img src="../../../static/images/arrow_r.png" alt="">
					</div>
				</div>
				<!-- 图片数字页面 -->
				<div class="number">
					<span>{{currentIndex+1}}</span>/<span>{{imgList.length}}</span>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import TitleVue from '@/components/Title.vue';
	export default {
		name: "mainService",
		data() {
			return {
				imgList: [{
						src: require("../../../static/images/img_business_01.png")
					},
					{
						src: require("../../../static/map.png")
					},
					{
						src: require("../../../static/img_client_06.png")
					},
					{
						src: require("../../../static/img_client_08.png")
					},
				],
				title: [{
						tit: '施工安装'
					},
					{
						tit: '群控节能'
					},
					{
						tit: '维修维保'
					},
					{
						tit: '清洗服务'
					},
				],
				currentIndex: 0, //现在是第几张
				time: 1500, //设置循环时间
				width: 0, //移动的长度
			};
		},
		created() {
			this.start()
      console.log(this.$route.params.index)
      if (this.$route.params){
        if (this.$route.params.index){
          this.left(this.$route.params.index)
        }
      }
		},
		computed: {
			changePic() {
				return {
					transform: `translate3d(${this.width}px, 0, 0)`, //主要实现核心
				};
			},
		},
		methods: {
			stop() {
				clearInterval(this.timer);
			},
			start() {
				this.timer = setInterval(() => {
					this.currentIndex++
					if (this.currentIndex > this.imgList.length - 1) {
						this.currentIndex = 0
					}
				}, 5000);
			},
			up() {
				clearInterval(this.timer)
				if (this.currentIndex === 0) {
					this.currentIndex = this.imgList.length - 1
				} else {
					this.currentIndex--
				}
			},
			down() {
				clearInterval(this.timer)
				if (this.currentIndex === this.imgList.length - 1) {
					this.currentIndex = 0
				} else {
					this.currentIndex++
				}
			},
			left(currentIndex) {
				clearInterval(this.timer)
				if (this.currentIndex === this.imgList.length - 1) {
					this.currentIndex = currentIndex
				} else {
					this.currentIndex = currentIndex
				}
			},
		},
	}
</script>

<style scoped lang="scss">
	.warpper {
		width: 90%;
		margin: 0 auto;
		background-color: #FFFFFF;

		.page {
			line-height: 2.75em;
			background-color: rgb(240, 240, 245);
			color: #999999;


			a {
				text-decoration: none;
				color: #999999;
			}
		}



		.center {
      width: 100%;
			display: flex;
			justify-content: space-between;
			background: #FFFFFF;
			padding: 2.25rem;

			.center-left {
				width: 8.5rem;
				height: 3.75rem;
				margin: 1.25rem;

				.main {
					width: 8.5rem;
					line-height: 3.31em;
					margin-left: 0.63em;
					border-bottom: 0.062px solid #ccc;
          cursor:pointer;
				}
			}

			.center-rigth {
				margin-left: 2rem;
        width: 70%;

				.rigth {
					display: flex;
					justify-content: space-between;

					.rigth-left {
						margin-right: 50px;
            display: flex;
            align-items: center;
					}

					.center-img {
						width: 100%;
						height: 100%;
						z-index: 10;
						// overflow: hidden;
						transition: 0.5s ease;

						img {
							width: 100%;
							border-radius: 10px;
						}
					}

					.rigth-rigth {
						margin-left: 50px;
            display: flex;
            align-items: center;
					}
				}

				.number {
					width: 2.125em;
					height: 0.9em;
					margin-left: 27.9em;
				}


			}
		}
	}

	.colo {
		text-align: left;
		width: 50%;
		border-bottom: 0.13em solid #077BC1;
	}
</style>
