import Vue from 'vue'
import Router from 'vue-router'

//组件模块
import Home from '@/views/home'
import Contact from '.././views/contact/contact.vue'
import information from "@/views/information/information";
import CompanyProfile from "@/views/companyProfile/CompanyProfile";
import informationDetail from '@/views/information/information-detail.vue'
import mainService from '@/views/mainService/mainService'
import layout from '@/components/Layout'

Vue.use(Router)

const router = new Router({
	// eslint-disable-next-line no-sparse-arrays
	routes: [
		{
			path: '/',
			name: 'layout',
			component:layout,
			children:[{
				path: '',
				redirect:'home',
			}]
		},
		{
			path: '/home',
			name: 'home',
			component: Home,
			meta:{
				top:true,
				foot:true,
				index:1
			}
		},
		{
			path: '/contact',
			name: 'contact',
			component: Contact,
			meta:{
				top:true,
				foot:true,
				index:5
			}
		},
		{
			path: '/mainService',
			name: 'mainService',
			component: mainService,
			meta:{
				top:true,
				foot:true,
				index:3
			}
		},
		{
			path: '/company',
			name: 'companyProfile',
			component: CompanyProfile,
			meta:{
				top:true,
				foot:true,
				index:2	
			}
		},
		,{
			path: '/information',
			name: 'information',
			component: information,
			meta:{
				top:true,
				foot:true,
				index:4
			}
		},
		,{
			path: '/information-detail',
			name: 'informationDetail',
			component: informationDetail,
			meta:{
				top:true,
				foot:true,
				index:4
			}
		}
	],
	mode: "history"
})

export default router