import request from "@/util/request";
import Vue from "vue";

export function getInformation(params){
    return request({
        url:'/openapi/information/list',
        method:'get',
        params
    })
}
export function getInfoDetail(params, data){
    console.log('params, data',params, data)
    return request({
        url:'/openapi/information/getInfo',
        method:'GET',
        params,
        data
    })
}
// export function list(){
//     return request({
//         url:'/dev-api/crm/banner/list',
//         method:'GET',
//     })
// }





export  default {
    getInformation,
    getInfoDetail
}
