<template>
  <div class="company-profile">
    <div class="content-top">
      <div class="content">
          <img src="../../../static/LOGO1.png" alt="">
          <p>
            秉承建立并改善人类舒适生存环境为目标的新技术公司一-北京冰峰酷科技发展有限公司，是从事中央空调系统设计与安装、机房群控节能系统设计与安装、中
            央空调系统清洗、中央空调设备维修保养等业务。提供空调设备的施工、安装、售后服务、维修、保养和专业清洗等配套服务的综合性科技服务实体。公司配备大批具
            有扎实专业知识、丰富现场经验的工程师，按照现代企业制度将规模化的资本、完整的运营
            体制、有效的市场网络整合为一体，以极大的热情、诚信的态度和全心全意的精神，为每一位选择我们的客户提供完善的服务!
          </p>
      </div>
      <div class="content-content">
        <p >
          我司是专业的建筑机电设备、制冷系统和中央空调工程系统集成与服务提供商，可为用户提供完善的制冷系统和中央空调系统技术方案、产品销售、
          现场协调、技术培训、设备巡检、年检、参数修改整定、排除故障、设备及系统的清洗、管家式的售后管理等全方位的服务。遵循公司客户至上，
          以客户的满意来推动我们不断改进的价值观，我公司一直以极大的热情、正直诚信的态度全心全意为客户服务。公司配备大批具有扎实专业知识、
          丰富现场经验的售后及清洗服务工程师及先进的服务保障仪器设备。专业化的服务团队，能为我们的客户提供长期、完美、周到的服务，
          与所有客户建立设备维护和清洗、技术更新、服务升级等长期合作关系
        </p>

      </div>
      <div style="padding-bottom: 25px;">
        <p>
          北京冰峰酷科技发展有限公司主营中央空调系统设计与安装、机房群控节能系统设计与安装.....
        </p>
      </div>
    </div>

  </div>
</template>

<script >
export default {
  name: "CompanyProfile",
}
</script>

<style scoped lang="scss">

.company-profile{
  width: 100%;
  margin: 0 auto;
  background: #FFF;
  margin-top: 8%;
}
.content-top{
  //padding: 45px 60px 18px;
  height: 100%;
  padding: 17px 19px;
}

.content{
  display: flex;
  padding-bottom: 15px;
  justify-content: space-between;
  align-items: center;
  img {
    margin-right: 25px;
    width: 50%;
    object-fit: contain;
  }
  p{
    text-indent: 28px;
    font-size: 14px;
    line-height: 24px;
    color: #333;
    margin: 0;
    padding: 5px 0;
  }
}
p{
  text-indent: 28px;
  font-size: 14px;
  line-height: 24px;
  color: #333;
  margin: 0;
  padding: 5px 0;
}
.content-text{
  width: 80%;
  padding-top: 3%;
  height: 100%;
}


.content-text p{
  text-indent: 28px;
  font-size: 14px;
  line-height: 24px;
  color: #333;
  margin: 0;
  padding: 5px 0;
}

.content-content{
	height: 100%;
  p{
    text-indent: 28px;
    font-size: 14px;
    line-height: 24px;
    color: #333;
    margin: 0;
    padding: 5px 0;
  }
}





 @media screen and(max-width:1500px) {
	 .content-content p{
		display: none;
	 }
	 .content-content{
	 	height: 0;
	 }
  }

</style>
