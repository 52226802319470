<template>
	<div class="end">
		<div class="end-left">
			地址:北京市朝阳区清河营东路2号院乐想汇3号楼1112室 电话:15101644348
    </div>
		<div class="end-right">
      <span>
        北京冰峰酷科技发展有限公司 Copyright 2021 All Rights Reserved &nbsp;&nbsp;&nbsp;&nbsp;
      </span>
        <a href="https://beian.miit.gov.cn/" target="_blank" >
          <div class="el-login-footer">
            <br>京ICP备2023004795号-1
         </div>
        </a>
		</div>
	</div>
</template>

<script>
	export default {
		name: "foot"
	}
</script>

<style scoped lang="scss">
	.end {
		width: 100%;
		display: flex;
		justify-content: space-between;
		//line-height: 3.5em;
    padding: 10px 0;
		font-size: 1em;
		background: #FFF;
		margin-right: 14em;
		.end-left {
			margin-left: 1.8em;
      display: flex;
      align-items: center;
		}
		.end-right {
			margin-right: 1.3em;

		}
	}
	@media screen and(max-width:1015px) {
		.end-left {
			display: none;

		}
	}
.el-login-footer{
  text-align: center;
  line-height: 10px;
}
  a, a:focus, a:hover {
    cursor: pointer;
    color: inherit;
    text-decoration: none;
  }

</style>
