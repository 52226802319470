<template>
<div class="warpper">
	<!-- 中间部分 -->
  <div class="center">
	  <!-- 联系我们文字部分 -->
  	<div class="center-left">
		<!-- 联系我们 -->
  		<div class="center-left-top">
  			<span>联系我们</span>
  		</div>
		<div class="center-left-bottom">
			<p>地址：北京市朝阳区清河营东路2号院乐想汇3号楼1112室</p>
			<p>电话：15101644348</p>
<!--			<p>传真：010-82980020</p>-->
			<p>网址：www.iceflowcool.com</p>
		</div>
  	</div>
	<!-- 联系图片 -->
    <div class="center-rigth">
        <img src="../../../static/map.png" style="object-fit:contain">
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: "contact",
  props:['contact']
}
</script>

<style scoped lang="scss">
.warpper {
  width: 100%;
  height: 100%;
  background-color: #F3F3F3;
  padding-top: 4rem;
}
.center{
	width: 80%;
	background: #FFFFFF;
	margin: 0 auto;
  padding: 39px 50px;
  display: flex;
  justify-content: space-between;
}
.center-left{
	width: 35%;
}
.center-left-top {
  padding-bottom: 40px;
}
.center-left-top span{
	font-size: 1em;
	font-family: Microsoft YaHei;
	font-weight: 700;
	color: #333333;
	line-height: 2em;
}
.center-left-bottom{
  p{
    font-size: 16px;
    color: #333;
    margin: 0;
    padding-bottom: 16px;
  }
}
.center-left-bottom p{
	font-size: 1em;
	font-family: Microsoft YaHei;
	font-weight: 400;
	color: #333333;
}

</style>
