//1. 导入axios对象
import axios from "axios";
import md5 from "js-md5";


//2. 创建对象实例，create方法
const Server = axios.create({
    //请求接口的基础地址 接口访问路径
    // baseURL: 'https://www.iceflowcool.com/',
    // baseURL: 'http://localhost:6688/',
    baseURL: '/dev-api',
    //设置超时时间
    timeout: 5000
})

// 请求拦截器
Server.interceptors.request.use(config => {

    // // 给管理后台的接口设置header头，添加Authorzation属性
    // let token = sessionStorage.getItem('token')
    // config.headers['Authorization'] = token//给header头添加token值
    //
    // return config
    const timeStamp = parseInt(new Date().getTime() / 1000) + '';
    const appId = '64fcc78f3f7c520dbdfb3da67e3b8647';
    const appSecret = '7563EFE17CFACDBF7FB751BC858C6B25';
    const requestParams = Object
        .entries({
            // ...config.data,
            ...config.params, timeStamp, appId, appSecret})
        .filter(([key]) => key !== 'pageSize')
        .filter(([key]) => key !== 'pageNum')
        .filter(([, val]) => {
            // 过滤null, undefined,空字符串，空对象
            if ((!!val || val === 0) && JSON.stringify(val) != '{}') {
                return true;
            }
        })
        .map(([key, val]) => ([key.toLocaleLowerCase(), val]))
        .sort()
        .map(([key, val]) => {
            // console.log([key, val])
            if (Object.prototype.toString.call(val) == '[object Object]') {
                return `${key}=${JSON.stringify(val)}`
            }
            return `${key}=${val}`;
        })
        .join('&')
    // console.log('requestParams',requestParams)
    const sign = md5(requestParams);
    config.headers = {
        ...config.headers,
        appId,
        timeStamp,
        sign
    }
    return config
}, error => {
    // 出现异常
    return Promise.reject(error);
})

// 响应拦截器
Server.interceptors.response.use(response =>{

    // 后台正常响应的状态，如果是200， 说明后台处理没有问题
   /*  if (response.status == 200) {
        return response.data;
    } */
    // return response.data 可以在这里统一的获取后台响应的数据进行返回，而这里面就没有请求头那些
    return response
}, error => {
    return Promise.reject(error);
})


export default Server // 导出自定义创建 axios 对象
