<template>
	<div id="app">
		<layout></layout>
	</div>
</template>

<script>
	import layout from '@/components/Layout'
	export default {
		name: 'App',
		components: {
			layout
		}
	}
</script>

<style>
	#app {
		font-family: Avenir, Helvetica, Arial, sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}

	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
	}

</style>
