<template>
<div class="warpper">
  <div class="page"><router-link to="/home">首页</router-link><span> > 行业资讯</span></div>
  <div class="talk-list">
      <div v-for="(item,index) in infoList" :key="index" class="talk-item"  @click="jump(item.id)">
        <img :src="url + item.imageId" alt="" >
        <div class="talk-content">
          <h3>{{item.title}}</h3>
          <p>发布时间：{{item.publishDate}}</p>
          <p class="informationDesc" >{{item.introduction}}</p>
        </div>
      </div>
  </div>
</div>
</template>

<script>
import {
  getInformation
} from "@/api";
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "information",
  data(){
    return {
      url:"https://www.iceflowcool.com/dev-api",
      // url:"http://localhost:6688/dev-api",
      infoList:[]
    }
  },
  mounted() {
	  this.$route.meta.index = 4
    this.getList()
  },
  methods:{
	  jump(id){
		  this.$router.push({
			  name:'informationDetail',
			  query:{
				  id
			  }
		  })
	  },
    getList(){
      getInformation().then( (res) => {
        this.infoList = res.data.rows
      })
    }
  }
}
</script>

<style scoped lang="scss">
  .warpper {
    width: 80%;
    margin: 0 auto;
    background-color: rgb(240,240,245);
    .page {
      line-height: 3.75em;
      background-color: rgb(240,240,245);
      color: #999999;
      cursor: pointer;
      a {
        text-decoration: none;
        color: #999999;
      }
    }
    .talk-list {
      background-color: #FFFFFF;
      margin-bottom: 3.75em;
    }
    .talk-item {
      display: flex;
      padding: 1.75em;
      width: 100%;
      border-bottom: 0.1em solid #EBEBEB ;
      cursor:pointer;
      img {
        display: block;
        width: 240px;
		    height: 140px;
        margin-right: 30px;
        object-fit:contain;
      }
      p {
        margin: 1.25em 0;
        color: #999999;
      }
      .talk-content {

      }
    }
  }
</style>
